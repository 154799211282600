import React, { useEffect } from 'react';

interface VideoPopupProps {
	youtubeLink: string;
	onClose: () => void;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ youtubeLink, onClose }) => {
	const closeModal = () => {
		onClose();
	};

	const handleKeyPress = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			closeModal();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<div
			className={`fixed inset-0 z-50 flex items-center justify-center bg-white`}
			onClick={closeModal}
		>
			<div className="modal-content h-72 w-96 rounded-md bg-black shadow-lg md:h-[500px] md:w-[800px]">
				<span
					className="absolute right-4 top-1 cursor-pointer text-5xl text-black"
					onClick={closeModal}
				>
					&times;
				</span>
				<div className="relative h-full w-full rounded-md bg-black">
					<iframe
						title="YouTube Video"
						className="absolute left-0 top-0 h-full w-full rounded-md bg-black"
						src={`https://www.youtube.com/embed/${youtubeLink}`}
						allowFullScreen
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default VideoPopup;
