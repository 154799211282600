import React, { useState } from 'react';
import Layout from '../layouts/Layout';

import AboutUs from '../components/AboutUs/AboutUs';
import CTA from '../components/CTA/CTA';
import ContactUs from '../components/ContactUs/ContactUs';

import heroVideo from '../images/hero-video.mp4';
import playIcon from '../images/play-icon.svg';
import transdevLogoWhite from '../images/transdev-logo-white.png';
import transdevLogoWhiteFr from '../images/transdev-logo-white-fr.png';
import rightArrowIcon from '../images/right-arrow-icon.svg';
import VideoPopup from '../components/VideoPopup/VideoPopup';

let isFrench = process.env.GATSBY_LANG === 'FR';

const entities = [
	{
		name: 'Transdev Canada',
		text: isFrench
			? 'Explorer les ouvertures à notre siège national'
			: 'Explore openings at our national headquarters.',
		href:
			(isFrench ? '/recherche/' : '/search/') + '?entity=Transdev Canada'
	},
	{
		name: isFrench
			? 'Transdev Ontario et Manitoba'
			: 'Transdev Ontario and Manitoba',
		text: isFrench
			? 'Explorer les ouvertures au Ontario et en Manitoba'
			: 'Explore open positions in Ontario and Manitoba.',
		href1: 'https://vworx.ca/',
		href2: 'https://patienttransport.ca/join-us/',
		linkText1: 'Transdev Ontario',
		linkText2: 'Transdev Manitoba'
	},
	{
		name: 'Transdev Québec',
		text: isFrench
			? 'Découvrir les possibilités offertes au Québec'
			: 'Discover available opportunities in Québec.',
		href:
			(isFrench ? '/recherche/' : '/search/') + '?entity=Transdev Québec'
	},
	{
		name: 'Transdev West',
		text: isFrench
			? 'Recherchez des offres d’emploi en : Alberta, Colombie-Britannique, Territoires du Nord-Ouest et Saskatchewan.'
			: 'Search job offers in: Alberta, British Columbia, Northwest Territories and Saskatchewan.',
		href: (isFrench ? '/recherche/' : '/search/') + '?entity=Transdev West'
	},
	{
		name: 'Rail',
		text: isFrench
			? 'Découvrir les postes vacants au sein de la division Rail en Ontario'
			: 'Discover open positions within the Rail division in Ontario.',
		href: (isFrench ? '/recherche/' : '/search/') + '?entity=Rail',
		linkText: 'Transdev Rail'
	}
];

const IndexPage = () => {
	const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);

	const openVideoPopup = () => {
		setIsVideoPopupOpen(true);
	};

	const closeVideoPopup = () => {
		setIsVideoPopupOpen(false);
	};

	return (
		<Layout
			title={'Transdev Careers'}
			desc={'Transdev Careers'}
			headerTransparent
			altLangPath="/"
		>
			<section
				className={`relative w-full bg-cover bg-center max-sm:bg-[url('../images/hero-bg.jpg')] lg:h-screen lg:min-h-[700px]`}
			>
				<div className="z-10 flex h-full w-full flex-col items-center justify-center gap-8 bg-[#2D2D316E] py-52 text-white max-lg:py-24 max-lg:pt-32">
					<h1 className="text-center text-7xl font-semibold max-md:text-6xl max-sm:text-5xl">
						{isFrench ? "Rejoignez l'équipe !" : 'Join the team!'}
					</h1>
					<a
						href={isFrench ? '/recherche/' : '/search/'}
						className="w-full max-w-[220px] bg-pri px-5 py-3 text-center text-base font-medium text-white transition-all hover:bg-[#940021]"
					>
						{isFrench ? 'Afficher les ouvertures' : 'View openings'}
					</a>
					<button
						className="flex items-center justify-center gap-2 text-xl leading-[1.114] text-white"
						onClick={openVideoPopup}
					>
						<img
							width={24}
							height={24}
							src={playIcon}
							alt="Play icon"
						/>
						{isFrench ? 'Visionner le film' : 'Watch the film'}
					</button>
				</div>
				{isVideoPopupOpen && (
					<VideoPopup
						youtubeLink={
							isFrench
								? '6kI2U96CHC8?si=di39iC-XZkj9zpyr&autoplay=1&rel=0'
								: 'ilO5cv0G4mQ?si=di39iC-XZkj9zpyr&autoplay=1&rel=0'
						}
						onClose={closeVideoPopup}
					/>
				)}
				<video
					className="absolute inset-0 -z-10 hidden h-full w-full object-cover sm:block"
					preload="none"
					loop
					muted
					autoPlay
					playsInline
				>
					<source src={heroVideo} type="video/mp4"></source>
				</video>
			</section>
			<section className="bg-[#F2F2F2]">
				<div className="container mx-auto w-full max-w-4xl px-4 py-[70px]">
					<h3 className="my-8 text-[28px] font-bold leading-[0.8] text-black">
						{isFrench
							? "Explorer les offres d'emploi par entité"
							: 'Explore our job offers by entity'}
					</h3>
					<div className="flex flex-wrap items-stretch justify-center gap-2 gap-y-8">
						{entities.map((entity) => {
							return (
								<a
									onClick={() => {
										sessionStorage.removeItem('filters');
									}}
									href={entity.href ? entity.href : undefined}
									className="group flex w-full max-w-[280px] flex-col gap-[26px] rounded-lg border border-[#E3E2E2] bg-white p-6 pb-5"
								>
									<div className="flex items-center justify-center bg-pri px-11 py-12">
										<img
											src={
												isFrench
													? transdevLogoWhiteFr
													: transdevLogoWhite
											}
											alt="Transdev Logo"
											width={150}
											height={44.51}
										/>
									</div>
									<div className="flex flex-col items-center justify-center gap-4 text-center">
										<p className="text-2xl font-bold leading-none">
											{entity.name}
										</p>
										<p className="text-[17px] leading-snug">
											{entity.text}
										</p>
										{entity.href && (
											<button className="flex cursor-pointer items-center gap-2 text-[15px] font-bold text-pri group-hover:text-[#940021]">
												{isFrench ? (
													<>
														Ouvertures chez
														<br />
														{entity.linkText ||
															entity.name}
													</>
												) : (
													(entity.linkText ||
														entity.name) +
													' Openings'
												)}
												<img
													src={rightArrowIcon}
													alt="Right Arrow Icon"
													width={14}
													height={10}
												/>
											</button>
										)}
										{entity.href1 && (
											<a
												href={entity.href1}
												className="flex cursor-pointer items-center gap-2 text-[15px] font-bold text-pri hover:text-[#940021]"
											>
												{isFrench ? (
													<>
														Ouvertures chez
														<br />
														{entity.linkText1}
													</>
												) : (
													entity.linkText1 +
													' Openings'
												)}
												<img
													src={rightArrowIcon}
													alt="Right Arrow Icon"
													width={14}
													height={10}
												/>
											</a>
										)}
										{entity.href2 && (
											<a
												href={entity.href2}
												className="flex cursor-pointer items-center gap-2 text-[15px] font-bold text-pri hover:text-[#940021]"
											>
												{isFrench ? (
													<>
														Ouvertures chez
														<br />
														{entity.linkText2}
													</>
												) : (
													entity.linkText2 +
													' Openings'
												)}
												<img
													src={rightArrowIcon}
													alt="Right Arrow Icon"
													width={14}
													height={10}
												/>
											</a>
										)}
									</div>
								</a>
							);
						})}
					</div>
				</div>
			</section>
			<section className="bg-white">
				<div className="container mx-auto w-full max-w-4xl px-4 pt-[70px]">
					<div className="flex flex-col gap-[28px]">
						<h3 className="text-[28px] font-bold leading-[0.8] text-black">
							{isFrench ? 'Nous embauchons' : 'We’re hiring'}
						</h3>
						<p className="text-2xl leading-snug">
							{isFrench
								? `Nous recherchons un personnel hautement qualifié et engagé avec un bon esprit d’équipe pour offrir à nos clients et passagers les solutions les plus sûres et les plus efficaces`
								: `We are looking for highly skilled and committed
							staff with good team spirit to offer our customers
							and passengers the safest and most efficient
							solutions possible.`}
						</p>
						<p className="text-2xl leading-snug">
							{isFrench
								? `Que ce soit dans des rôles opérationnels ou de soutien, apprenez quel rôle est le mieux adapté pour vous et devenez un « agent de changement de mobilité »`
								: `Whether in operational or support roles, learn which
							role is the best fit for you and become a “mobility
							change maker”!`}
						</p>
					</div>
					<AboutUs />
				</div>
			</section>
			<CTA />
			<ContactUs />
		</Layout>
	);
};

export default IndexPage;
