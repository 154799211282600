import React from 'react';

import circles from '../../images/circles.png';

let isFrench = process.env.GATSBY_LANG === 'FR';

export default function CTA() {
	return (
		<section className="relative bg-sec">
			<div className="absolute bottom-0 right-0 w-[625px]">
				<img
					src={circles}
					className=""
					alt="Cirles"
					width={625}
					height={195}
				/>
			</div>
			<div className="container relative z-10 mx-auto w-full max-w-4xl px-4 py-7">
				<div className="flex flex-col gap-[28px]">
					<h3 className="text-4xl font-bold uppercase text-pri max-sm:text-3xl">
						{isFrench ? 'RECRUTEMENT' : 'Recruitment'}
					</h3>
					<p>
						{isFrench
							? `Transdev vous offre la possibilité d'avancer dans le monde passionnant et changeant du transport`
							: `Transdev is offering you the opportunity to move forward
						in an exciting and changing world of transport`}
					</p>
					<a
						href={isFrench ? '/recherche/' : '/search/'}
						className="w-full max-w-[220px] bg-pri px-5 py-3 text-center text-base font-medium text-white transition-all hover:bg-[#940021]"
					>
						{isFrench ? 'Voir les ouvertures' : 'View openings'}
					</a>
				</div>
			</div>
		</section>
	);
}
